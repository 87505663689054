import ExternalLink from '@uikit/components/ExternalLink/ExternalLink';
import DiscordNeutral from '@uikit/icons/DiscordNeutral';
import LinkedInNeutral from '@uikit/icons/LinkedInNeutral';
import TelegramNeutral from '@uikit/icons/TelegramNeutral';
import XNeutral from '@uikit/icons/XNeutral';
import YoutubeNeutral from '@uikit/icons/YoutubeNeutral';

import { DISCORD_URL } from '@shared/constants';

export const magicSquareSocials: { id: number; Icon: typeof XNeutral; link: string }[] = [
  {
    id: 1,
    Icon: XNeutral,
    link: 'https://twitter.com/MagicSquareio',
  },
  {
    id: 2,
    Icon: DiscordNeutral,
    link: DISCORD_URL,
  },
  {
    id: 3,
    Icon: TelegramNeutral,
    link: 'https://t.me/magicsquare_official',
  },
  {
    id: 4,
    Icon: TelegramNeutral,
    link: 'https://t.me/magicsquare_announcements',
  },
  {
    id: 5,
    Icon: YoutubeNeutral,
    link: 'https://www.youtube.com/channel/UCJjHbHzZXe0T5gcn97baIbA',
  },
  {
    id: 6,
    Icon: LinkedInNeutral,
    link: 'https://www.linkedin.com/company/magicsquare',
  },
];

export const SocialNetworks = () => {
  return (
    <nav className="flex gap-2 md:gap-4">
      {magicSquareSocials.map(({ id, link, Icon }) => (
        <ExternalLink
          href={link}
          key={id}
          className="icon-xs md:icon text-neutral-500"
        >
          <Icon />
        </ExternalLink>
      ))}
    </nav>
  );
};
