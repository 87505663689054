import React from 'react';

import classNames from 'classnames';
import { useIsClient } from 'usehooks-ts';

import ExternalLink from '@uikit/components/ExternalLink/ExternalLink';
import { DiscordCircleSmall } from '@uikit/icons/DiscordCircleSmall';
import DiscordNeutral from '@uikit/icons/DiscordNeutral';
import { TelegramCircle } from '@uikit/icons/TelegramCircle';
import TelegramNeutral from '@uikit/icons/TelegramNeutral';
import XNeutral from '@uikit/icons/XNeutral';

import { SocialNetworks } from '@shared/common/components/SocialNetworks';
import { LAUNCHPAD_INTERNAL_ROUTES, LAUNCHPAD_URL } from '@shared/constants';

export const launchpadSocials: {
  id: string;
  Icon: typeof XNeutral;
  CircleIcon: typeof XNeutral;
  link: string;
}[] = [
  {
    id: 'social-launchpad-telegram-1',
    Icon: TelegramNeutral,
    CircleIcon: TelegramCircle,
    link: LAUNCHPAD_URL.magicLaunchpadTG,
  },
  {
    id: 'social-launchpad-discord',
    Icon: DiscordNeutral,
    CircleIcon: DiscordCircleSmall,
    link: LAUNCHPAD_URL.discord,
  },
  {
    id: 'social-launchpad-x',
    Icon: XNeutral,
    CircleIcon: XNeutral,
    link: LAUNCHPAD_URL.x,
  },
];

const Footer = ({ className }: { className?: string }) => {
  const isClient = useIsClient();
  const version = `version: ${process.env.NEXT_PUBLIC_LAUNCHPAD_VERSION || 'N/A'}`;
  const showVersion =
    isClient && typeof localStorage !== 'undefined' && localStorage.getItem('presentTag') === '1';

  return (
    <footer className={classNames('main-container w-full', className)}>
      <div className="pt-3 pb-5 md:py-6 flex gap-2 md:justify-between items-center border-t border-primary-300 max-md:flex-col max-md:items-center">
        <div className="flex items-center divide-x divide-neutral-200">
          <ExternalLink
            href={LAUNCHPAD_URL.privacyPolicy}
            className="max-md:text-3xs px-2 text-neutral-200 font-medium hover:underline"
          >
            Privacy Policy
          </ExternalLink>
          <ExternalLink
            href={LAUNCHPAD_URL.termsAndConditions}
            className="max-md:text-3xs px-2 text-neutral-200 font-medium hover:underline"
          >
            Terms and Conditions
          </ExternalLink>
          <ExternalLink
            href={LAUNCHPAD_INTERNAL_ROUTES.howItWorks}
            className="max-md:text-3xs px-2 text-neutral-200 font-medium hover:underline"
          >
            How it Works
          </ExternalLink>
          <ExternalLink
            href={LAUNCHPAD_URL.howToGet}
            className="max-md:text-3xs px-2 text-neutral-200 font-medium hover:underline"
          >
            Apply as a KOL
          </ExternalLink>

          {showVersion && (
            <span className="max-md:text-3xs px-2 text-neutral-200 font-medium">{version}</span>
          )}
        </div>

        <SocialNetworks />
      </div>
    </footer>
  );
};

export default Footer;
